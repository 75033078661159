




















import { Component, Vue } from 'vue-property-decorator'
import Common from '../../common/Common'
import PolicyComponent from '@/components/PolicyComponent.vue'

@Component({
  components: {
    PolicyComponent
  },
})
export default class UsePolicy extends Vue {

  mounted () {
  }
}
